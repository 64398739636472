<template>
  <router-view :key="$route.fullPath" />
</template>
<script>
import proxy from "@/api/proxy.js";

export default {
  data() {
    return {};
  },
  created() { },
  mounted() {
    window.vue = this
  },
  watch: {},
  methods: {
    getProxy() {
      return proxy;
    },
  },
};
</script>
<style></style>